
import { defineComponent, PropType } from 'vue';

import { IMemberPermissions } from '../../entities/memberPermissions';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';

export default defineComponent({
  data() {
    return {
      permissions: {
        manageSettings: false,
        readPlayers: false,
        manageApiTokens: false,
        administrator: false,
      } as IMemberPermissions,
    };
  },
  emits: ['update:modelValue'],
  methods: {
    getFormattedDate(date: Date) {
      return formatDate(date);
    },
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    runIfTrue(value: boolean, func: () => void) {
      if (value) {
        func();
      }
    },
  },
  mounted() {
    this.permissions = this.modelValue;
    this.$watch('permissions', (newValue: IMemberPermissions) => {
      this.$emit('update:modelValue', newValue);
    }, { deep: true });
  },
  props: {
    modelValue: {
      type: Object as PropType<IMemberPermissions>,
      required: true,
    },
  },
});
