
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IMember } from '../../../entities/member';
import { IOutgoingInvite } from '../../../entities/outgoingInvite';

import {
  createInvite, getInvites, getMembers, ICreateInviteData, revokeInvite,
} from '@/services/steamcord/orgs';

import { IPaginationResponse } from '../../../services/steamcord/paginationResponse';
import { validateEmail } from '@/services/validation';
import { useAuthStore } from '@/store/authStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { formatDate } from '@/util/formatDate';
import { getRandomInt } from '@/util/getRandomInt';
import PermissionsSwitches from '@/components/dashboard/PermissionsSwitches.vue';
import TableRow from '@/components/dashboard/TableRow.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const members = await getMembers(currentOrg.id);
    const outgoingInvites = await getInvites(currentOrg.id);

    next((vm) => {
      (vm as any).setMembersResponse(members);
      (vm as any).setInvites(outgoingInvites);
    });
  },
  components: {
    PermissionsSwitches,
    TableRow,
  },
  computed: {
    ...mapState(useAuthStore, ['customerID']),
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
  },
  data() {
    return {
      createInviteData: {
        email: '',
        permissions: {
          manageSettings: false,
          readPlayers: false,
          manageApiTokens: false,
          administrator: false,
        },
      } as ICreateInviteData,
      emailError: '',
      formLoading: false,
      invites: [] as IOutgoingInvite[],
      loadingInvites: [] as number[],
      membersResponse: undefined as IPaginationResponse<Partial<IMember>> | undefined,
      membersLoading: true,
    };
  },
  methods: {
    getFormattedDate(date: Date) {
      return formatDate(date);
    },
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    async onSubmit() {
      if (this.formLoading || !this.currentOrg) {
        return;
      }

      let error: string | undefined = validateEmail(this.createInviteData.email);
      if (error) {
        this.emailError = error;
        return;
      }

      this.formLoading = true;

      error = await createInvite(this.currentOrg.id, this.createInviteData);
      this.emailError = error ?? '';

      if (!error) {
        this.invites = await getInvites(this.currentOrg.id);
        this.createInviteData.email = '';
      }

      this.formLoading = false;
    },
    async revokeInvite(id: number) {
      if (this.loadingInvites.includes(id) || !this.currentOrg) {
        return;
      }

      this.loadingInvites.push(id);

      await revokeInvite(this.currentOrg.id, id);

      this.invites = await getInvites(this.currentOrg.id);

      this.loadingInvites = this.loadingInvites.filter((i) => i !== id);
    },
    runIfTrue(value: boolean, func: () => void) {
      if (value) {
        func();
      }
    },
    setInvites(invites: IOutgoingInvite[]) {
      this.invites = invites;
    },
    setMembersResponse(membersResponse: IPaginationResponse<Partial<IMember>>) {
      this.membersResponse = membersResponse;
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
